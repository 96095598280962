import React, { useContext, useEffect } from 'react';
import { returnWhatsapp } from '../../utils/browser';
import { Head } from '../../components/header/head';
import { useStaticQuery, graphql } from 'gatsby';
import { LangContext } from '../../context/lang.context';

const GenerateBillPage = ({ getToken }: PageProps) => {
  const token = getToken();
  const { t } = useContext(LangContext);

  const data = useStaticQuery(graphql`
    query {
      allI18NJson {
        nodes {
          locale
          clientId
          GENERATE_BILL {
            MESSAGE
          }
        }
      }
    }
  `);
  useEffect(() => {
    returnWhatsapp({
      message: t(data).GENERATE_BILL.MESSAGE + ' ' + token,
    });
  });

  return <Head />;
};

export default GenerateBillPage;
